import React from "react";
import "../styles/Landing.css";
import Logo from "../Assets/imgs/logo.webp";
import mainImage from "../Assets/imgs/main-image.webp";
import dayFree from "../Assets/imgs/day-free.webp";

type Props = {
  showLoader: boolean;
  errorMsg: string;
  showError: boolean;
  onSubscribePressed: () => void;
  showPlay: boolean;
  confirmLoader: boolean;
  phoneNo: string;
  onHandlePlay: () => void;
};

const Landing = (props: Props) => {
  return (
    <>
      <div className="landing-page">
        <div className="content">
          <img
            className="logo"
            src={Logo}
            width="55"
            height="57"
            alt="logo image"
          />
          <div className="main-title">
            Enter Tournaments,
            <br />
            <span>Compete, and </span>
            <br />
            <span>Win</span> <span className="yellow">Weekly Prize</span>
          </div>
          <img
            className="main-img"
            src={mainImage}
            width="526"
            height="481"
            alt="main image"
          />
          <div className="form-content">
            <img
              className="day-free"
              src={dayFree}
              width="262"
              height="39"
              alt="day-free image"
            />
            <h4>Rs. 10/Day! (Including Tax)</h4>
            {props.showError && <p className="error">{props.errorMsg}</p>}
            {props.phoneNo && (
              <>
                {props.showPlay ? (
                  <>
                    {props.confirmLoader ? (
                      <button className="disable-btn btn-play">Confirm</button>
                    ) : (
                      <button
                        className="btn-play"
                        onClick={() => props.onSubscribePressed()}
                      >
                        Confirm
                      </button>
                    )}
                  </>
                ) : (
                  <button className="btn-play" onClick={props.onHandlePlay}>
                    Play
                  </button>
                )}
              </>
            )}
            <p className="foot-text">
              I agree to daily recurring charges deduction from my mobile
              balance. Free trial is for new users only
            </p>
            <a href="/terms" className="term">
              Terms & Condition
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
